<template>
  <el-container v-loading="loading">
    <el-header style="display: flex; align-items: center;">
      <h1 style="font-size: 20px; font-weight: 700;">{{ title }}</h1>
    </el-header>
    <el-main>
      <CustomForm ref="CustomForm" :form-model="formModel" :form-items="formItem" :form-rules="formRules" />
    </el-main>
    <el-footer style="display: flex; justify-content: flex-end; align-items: center;">
      <el-button size="small" type="primary" :disabled="categoryType === 0" @click="handleConfirm">保存</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
export default {
  name: 'CategoryForm',
  components: { CustomForm },
  data() {
    return {
      loading: false,
      title: '分类：',
      categoryType: '',
      formModel: {
        name: ''
      }
    }
  },
  computed: {
    formItem() {
      return {
        name: {
          elColSpan: 24,
          component: 'CustomFormText',
          label: '名称',
          maxlength: 8,
          disabled: this.categoryType === 0
        }
      }
    },
    formRules() {
      return {
        name: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['miniappMenuCategorySave']),
    openLoading() {
      this.loading = true
    },
    closeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    initData(data) {
      Object.assign(this, this.$options.data.call(this))

      this.openLoading()

      this.title += data.name
      this.categoryType = data.type
      this.formModel.name = data.name

      this.$refs.CustomForm.$refs.form.clearValidate()

      this.closeLoading()
    },
    handleConfirm() {
      this.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res && this.categoryType !== 0) {
          this.miniappMenuCategorySave(this.formModel).then(res => {
            if (res.code !== 200) {
              this.closeLoading()
              this.$message.error(res.msg)
              return
            }
            this.$message.success('修改成功')
            this.$emit('submit', this.formModel)
            this.closeLoading()
          }).catch(() => {
            this.closeLoading()
          })
        } else {
          this.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
