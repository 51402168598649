<template>
  <div v-loading="loading" class="wrapper">
    <div>
      <el-alert title="点击分类/导航进行编辑。" type="warning" :closable="false" center />
    </div>
    <el-row ref="ElRow" :class="{'flex-align-items-center': elRowWidth > 934 || !checkMenuData }" :gutter="10">
      <el-col :xs="24" :sm="24" :md="12" :lg="10">
        <div class="left">
          <div class="left-main">
            <div class="category">
              <el-button
                v-for="(item, index) in category"
                :key="index"
                size="small"
                :type="checkCategoryData && item.id === checkCategoryData.id ? 'primary' : ''"
                :class="{'check': checkCategoryData && item.id === checkCategoryData.id}"
                round
                @click="handleCategory(item, index)"
              >
                {{ item.name }}
              </el-button>
            </div>
            <el-divider />
            <div class="menu">
              <div
                v-for="(item, index) in showAllMenu"
                :key="index"
                class="menu-item"
                :class="{'show': item.status === 1 && checkCategoryData.type in item.categoryType}"
                @click="handleMenu(item, index)"
              >
                <el-image class="image" :src="item.icon" fit="contain">
                  <div slot="error" class="error-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
                <div class="text">{{ item.name }}</div>
                <div v-show="checkMenuData && item.id === checkMenuData.id" class="check" />
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="10">
        <el-row :gutter="10">
          <el-col :xs="checkMenuData ? 12 : 24" :sm="checkMenuData ? 12 : 24" :md="24" :lg="24">
            <CategoryForm v-if="checkCategoryData" ref="CategoryForm" class="right" @submit="categorySubmit" />
          </el-col>
          <el-col :xs="12" :sm="12" :md="24" :lg="24">
            <MenuForm v-if="checkMenuData" ref="MenuForm" :category="category" :check-category-data="checkCategoryData" class="right" @submit="menuSubmit" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CategoryForm from './components/categoryForm'
import MenuForm from './components/menuForm'

export default {
  name: 'MiniappBanner',
  components: { CategoryForm, MenuForm },
  data() {
    return {
      loading: true,
      elRowWidth: 0,
      category: [],
      allMenu: {},
      showAllMenu: [],
      checkCategoryData: null,
      checkCategoryIndex: 0,
      checkMenuData: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.elRowWidth = this.$refs.ElRow.$el.clientWidth
    })
    window.onresize = () => {
      this.elRowWidth = this.$refs.ElRow.$el.clientWidth
    }
    // 获取分类
    this.miniappMenuCategoryList().then(res => {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.category = res.data

      // 获取导航
      this.miniappMenuPublicMenuList({ hasTenant: true }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }

        // 格式化所有导航
        this.formatAllMenu(res.data).then(() => {
          this.closeLoading()

          // 默认选中
          if (this.category.length) {
            this.handleCategory(this.category[0], 0)
          }
        })
      }).catch(() => {})
    }).catch(() => {})
  },
  methods: {
    ...mapActions(['miniappMenuCategoryList', 'miniappMenuPublicMenuList']),
    openLoading() {
      this.loading = true
    },
    closeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    formatAllMenu(menuData) {
      return new Promise(resolve => {
        const allMenu = {}
        menuData.forEach(item => {
          item.items.forEach(ite => {
            if (ite.id in allMenu) {
              allMenu[ite.id].categoryType[item.type] = item.type
            } else {
              const categoryType = {}
              categoryType[item.type] = item.type
              allMenu[ite.id] = {
                id: ite.id,
                module: ite.module,
                name: ite.name,
                icon: ite.icon,
                url: ite.url,
                status: ite.status,
                categoryId: item.categoryId,
                categoryType: categoryType
              }
            }
          })
        })
        this.allMenu = allMenu
        resolve()
      })
    },
    setShowAllMenu() {
      return new Promise(resolve => {
        const allMenu = Object.values(this.allMenu)
        const openData = []
        const closeData = []
        allMenu.forEach(res => {
          if (res.status === 1 && this.checkCategoryData.type in res.categoryType) {
            openData.push(res)
          } else {
            closeData.push(res)
          }
        })
        resolve([...openData, ...closeData])
      })
    },
    handleCategory(item, index) {
      this.checkCategoryData = item
      this.checkCategoryIndex = index
      this.checkMenuData = null

      this.$nextTick(() => {
        this.$refs.CategoryForm.initData(this.checkCategoryData)
      })

      this.setShowAllMenu().then(res => {
        this.showAllMenu = res
      })
    },
    categorySubmit(data) {
      this.$set(this.category[this.checkCategoryIndex], 'name', data.name)
      this.handleCategory(this.category[this.checkCategoryIndex], this.checkCategoryIndex)
    },
    handleMenu(item) {
      this.checkMenuData = item
      this.$nextTick(() => {
        this.$refs.MenuForm.initData(this.allMenu[item.id])
      })
    },
    menuSubmit(data) {
      const func = () => {
        return new Promise(resolve => {
          this.allMenu[data.id]['name'] = data.name
          this.allMenu[data.id]['icon'] = data.icon
          this.allMenu[data.id]['url'] = data.url
          this.allMenu[data.id]['status'] = data.status
          this.allMenu[data.id]['categoryType'] = {}
          data.types.split(',').forEach(item => {
            this.allMenu[data.id]['categoryType'][Number(item)] = Number(item)
          })
          resolve()
        })
      }
      func().then(() => {
        this.setShowAllMenu().then(res => {
          this.showAllMenu = res
          this.handleMenu(this.allMenu[data.id])
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.2);

    .flex-align-items-center {
      display: flex;
      align-items: center;
    }

    .left {
      margin: 10px 0;
      padding: 20px;
      /*background-color: #FFF;*/
      border-radius: 20px;

      .left-main {
        width: 296px;
        min-height: 560px;
        margin: auto;
        padding: 20px;
        border-radius: 40px;
        box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.7), 0 0 2px 5px inset rgba(0, 0, 0, 0.8);
        background: linear-gradient(180deg, rgba(246, 206, 98, 0.5) 0%, rgba(246, 206, 98, 0.2) 100%);

        .category {
          display: flex;
          justify-content: space-evenly;

          .check {
            background: rgba(246, 206, 98, 1);
            border-color: rgba(246, 206, 98, 1);
            color: rgba(51, 51, 51, 1);
          }
        }

        .menu {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;

          .menu-item {
            width: 54px;
            margin: 7px 3px;
            padding: 7px;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            position: relative;

            .image {
              width: 44px;
              height: 44px;
              background-color: rgba(136, 136, 136, 0.2);
              border-radius: 100%;

              ::v-deep .error-slot {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
              }
            }

            .text {
              color: rgba(51, 51, 51, 1);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .check {
              width: 34px;
              height: 5px;
              background-color: rgba(255, 0, 0, 1);
              overflow: hidden;
              border-radius: 34px;
              position: absolute;
              bottom: -7px;
              left: 17px;
            }
          }

          .show {
            background: rgba(246, 206, 98, 1);
          }
        }
      }
    }

    .right {
      margin: 10px 0;
      background-color: #FFF;
      border-radius: 5px;
    }
  }
</style>
